import React from 'react';
import Logo  from './img/logo.svg';
import './scss/App.scss';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      mounted: false,
      guides: false,
      overlayIsActive: false,
      aboutSectionIsActive: false,
      contactSectionIsActive: false
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  activeOverlayHandler(section) {
    switch (section) {
      case 'about':
        this.setState(state => ({ 
          overlayIsActive: true,
          aboutSectionIsActive: true,
          contactSectionIsActive: false 
        }));
        break;
      case 'contact':
        this.setState(state => ({ 
          overlayIsActive: true,
          aboutSectionIsActive: false,
          contactSectionIsActive: true 
        }));
        break;
      default:
        this.setState(state => ({ 
          overlayIsActive: !state.overlayIsActive,
          aboutSectionIsActive: false,
          contactSectionIsActive: false 
        }));
        break;
    }
  }

  render() {
    const { mounted, guides, overlayIsActive, aboutSectionIsActive, contactSectionIsActive } = this.state;

    return (
      <div className={`App ${mounted ? 'mounted' : ''}`}>
        <section className={`background-image ${overlayIsActive ? 'darken' : ''}`}></section>
        <section className={`overlay ${overlayIsActive ? 'is-active' : ''}`}>
          <div className="overlay__content offset--1 cols--2">
            <div className="hamburger" onClick={() => this.activeOverlayHandler()}>
              <div className="hamburger__inner">
                <div className="hamburger__line hamburger__line--1"></div>
                <div className="hamburger__line hamburger__line--2"></div>
              </div>
            </div>
            <div className={`txt-container txt-container--about ${aboutSectionIsActive ? 'is-active' : ''}`}>
              <p className="txt txt--L">Siras is a New York-based integrated real estate group building commercial, hospitality and residential developments of exceptional quality. Intimately familiar with&nbsp;the city’s most vibrant neighborhoods, and with complementary expertise in all facets of development, our three partners are thoroughly involved in all aspects of each project, from conception through construction and sale.</p>
              <p className="txt">Collaborating with world-class architects and innovative designers, Siras’s portfolio is a distinctive addition to the city’s landscape. By redrawing the parameters of how we live, work and interact, Siras is changing the narrative of real estate development in New York.</p>
            </div>
            <div className={`txt-container txt-container--contact ${contactSectionIsActive ? 'is-active' : ''}`}>
              <p className="txt txt--L txt--mobile">520 West 27th Street, Suite 302<br/>New York, NY 10001</p>
              <p className="txt txt--L txt--mobile"><a href="mailto:info@sirasdevelopment.com">info@sirasdevelopment.com</a><br/>T&nbsp;&nbsp;<a href="tel:2128048784">212 804 8784</a> — F&nbsp;&nbsp;212 924 1037</p>
            </div>
          </div>
        </section>
        <header className="App-header">
          <div className="cols--1">
            <img src={Logo} className="logo" alt="Sirus logo" />
          </div>
          <nav className="cols--1">
            <div className={`hamburger ${overlayIsActive ? 'is-active' : ''}`} onClick={() => this.activeOverlayHandler()}>
              <div className="hamburger__inner">
                <div className="hamburger__line hamburger__line--1"></div>
                <div className="hamburger__line hamburger__line--2"></div>
              </div>
            </div>
            <ul>
              <li onClick={() => this.activeOverlayHandler('about')}>About</li>
              <li onClick={() => this.activeOverlayHandler('contact')}>Contact</li>
            </ul>
          </nav>
        </header>
        {guides && <div className="guides">
          <div className="guides__col"></div>
          <div className="guides__col"></div>
          <div className="guides__col"></div>
        </div>}
      </div>
    );
  }
}

export default App;
